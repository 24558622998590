import React, { useEffect, useState } from "react";
import {
  useWallet,
  ConnectModal,
  ErrorCode,
  useAccountBalance,
} from "@suiet/wallet-kit";
import { Dropdown, Button, Menu, Space } from "antd";
import { DownOutlined } from "@ant-design/icons";
import axios from "axios";
import { BaseUrl } from "../Common/Apis/Apis";
import toast from "react-hot-toast";

function ConnectWallet() {
  const wallet = useWallet();
  const { balance } = useAccountBalance();
  const WalletBalance = Number(balance) / 1e9;
  const [showModal, setShowModal] = useState(false);
  const [addressDisplay, setAddressDisplay] = useState("");

  useEffect(() => {
    if (wallet.connected && wallet.account?.address) {
      const shortenedAddress = `${wallet.account.address.slice(0, 5)}...`;
      setAddressDisplay(shortenedAddress);
      setShowModal(false);
    }
  }, [wallet.account?.address, wallet.connected]);

  const handleDisconnect = () => {
    wallet.disconnect();
    setAddressDisplay("");
  };

  const handleLogin = async () => {
    try {
      const response = await axios.post(`${BaseUrl}client/login`, {
        walletAddress: wallet?.account?.address,
      });
      localStorage.setItem("UserAuth", JSON.stringify(response?.data?.data));
    } catch (error) {
      toast.error(error?.response?.data?.error || "Something went wrong.");
    }
  };

  useEffect(() => {
    if (wallet.connected && wallet.account?.address) {
      localStorage.setItem("walletAddress", wallet.account.address);
      handleLogin();
    } else {
      localStorage.removeItem("walletAddress" && "UserAuth");
    }
  }, [wallet.connected, wallet.account?.address]);

  const menu = (
    <Menu className="text-white bg-dark DisconnectWalletBtn">
      <Menu.Item
        key="1"
        className="text-center text-white bg-dark DisconnectWallet"
        onClick={handleDisconnect}
      >
        <strong className="fw-bold">Disconnect Wallet</strong>
      </Menu.Item>
    </Menu>
  );

  return (
    <div>
      {wallet.connected ? (
        <Dropdown overlay={menu} trigger={["click", "hover"]}>
          <Space>
            <Button className="ConnectedWallet">
              {WalletBalance?.toFixed(2)} SUI {addressDisplay} <DownOutlined />
            </Button>
          </Space>
        </Dropdown>
      ) : (
        <span
          onClick={() => setShowModal(true)}
          className="px-3 py-2 rounded ConnectedWallet"
        >
          Connect Wallet
        </span>
      )}

      <ConnectModal
        open={showModal}
        onOpenChange={setShowModal}
        onConnectError={(error) => {
          if (error.code === ErrorCode.WALLET__CONNECT_ERROR__USER_REJECTED) {
            console.warn("user rejected the connection");
            toast.error("User rejected the connection");
          } else {
            console.warn("Unknown connect error: ", error);
            toast.error("Unknown connect error: " + error.message);
          }
        }}
      />
    </div>
  );
}

export default ConnectWallet;
// import React, { useEffect } from "react";
// import { ConnectButton, useWallet, ErrorCode } from "@suiet/wallet-kit";
// import "./Navbar.css";
// import toast from "react-hot-toast";
// import axios from "axios";
// import { BaseUrl } from "../Common/Apis/Apis";

// function ConnectWallet() {
//   const wallet = useWallet();

//   const handleLogin = async () => {
//     try {
//       const response = await axios.post(`${BaseUrl}client/login`, {
//         walletAddress: wallet?.account?.address,
//       });
//       // toast.success(response?.data?.message);
//       localStorage.setItem("UserAuth", JSON.stringify(response?.data?.data));
//     } catch (error) {
//       toast.error(error?.response?.data?.error || "Something went wrong.");
//     }
//   };

//   useEffect(() => {
//     if (wallet.connected && wallet.account?.address) {
//       localStorage.setItem("walletAddress", wallet.account.address);
//       handleLogin();
//     } else {
//       localStorage.removeItem("walletAddress" && "UserAuth");
//     }
//   }, [wallet.connected, wallet.account?.address]);

//   return (
//     <div>
//       <ConnectButton
// onConnectError={(error) => {
//   if (error.code === ErrorCode.WALLET__CONNECT_ERROR__USER_REJECTED) {
//     console.warn(
//       "user rejected the connection to " + error.details?.wallet
//     );
//     toast.error(
//       "User rejected the connection to " + error.details?.wallet
//     );
//   } else {
//     console.warn("Unknown connect error: ", error);
//     toast.error("Unknown connect error: " + error.message);
//   }
// }}
//       >
//         Connect Wallet
//       </ConnectButton>
//     </div>
//   );
// }

// export default ConnectWallet;
