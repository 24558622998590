import { Transaction } from '@mysten/sui/transactions';
import { useWallet } from '@suiet/wallet-kit';
import { Button } from 'antd';
import React from 'react';
import toast from 'react-hot-toast';

const Unstake = () => {
  const wallet = useWallet();

  const handleUnstake = async () => {
    if (!wallet.connected) {
      toast.error("Wallet not connected!");
      return;
    }

    const txb = new Transaction();
    


    // Debugging logs
    console.log('Building transaction for unstaking...');

    txb.moveCall({
      target: `0xecab9795bd4748787b020f1bcbe3134565bac1de94c57e7e598e798fef4adaf3::sustaking::stake`,
      arguments: [
        txb.object('0x06'), // Object ID of the stake
        txb.object('0x3ee3f1b3b18615bfbf36e139b65ba59c60ff3c784ac6447ee66a6c1de92121e7'), // Object ID of the stake
      ],
      typeArguments: ["0xd5b84d1d413dddcf260274eb0fb1cd974c4144980a381ac3c04f967042f4a13e::sutoken::SUTOKEN"],
    });

    // Log the transaction block to check if it's properly created
    console.log("Transaction Block:", txb);

    try {
      const result = await wallet.signAndExecuteTransaction({
        transactionBlock: txb,
      });

      console.log(result, "Transaction Result");
      toast.success("Unstake transaction successful!");
    } catch (error) {
      toast.error(
        error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
          ? "Rejected by user"
          : error.message || "Transaction failed!"
      );
      console.error("Error executing unstake transaction:", error);
    }
  };

  return (
    <div className='mt-5 d-flex w-50 mx-auto'>
      <Button onClick={handleUnstake}>Unstake</Button>
    </div>
  );
};

export default Unstake;
