import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Market from "./Pages/Market/Market";
import MarketDetail from "./Pages/Market/MarketDetail";
import Hadder from "./Components/Navbar/Navbar";
import { Toaster } from "react-hot-toast";
import Login from "./Components/Auth/Login/Login";
import Forgot from "./Components/Auth/Forgot/Forgot";
import ResetPwd from "./Components/Auth/Forgot/Resetpwd";
import Users from "./Pages/Users/Users";
import UserWithdralRequest from "./Components/Modal/UserWithdral/UserWithdralRequest";
import UserTransaction from "./Components/User/UserTransaction";
import AllTransaction from "./Components/Modal/AllTransaction/AllTransaction";
import LandingPage from "./Pages/LandingPage/LandingPage";
import Stake from "./Pages/Stake/Stake";

function App() {
  return (
    <>
      <Router>
        <Toaster position="top-right" reverseOrder={false} />
        <Hadder />
        <Routes>
          {/* user */}
          <Route path="/vault" element={<Users/>} />
          <Route path="/" element={<LandingPage/>} />
          <Route path="/transaction_history" element={<UserTransaction />} />
          <Route path="/stake" element={<Stake />} />
          {/* admin */}
          <Route exact path="/admin/" element={<Market />} />
          <Route path="/admin/market-detail/:tokenName" element={<MarketDetail />} />
          <Route path="/admin/login" element={<Login />} />
          <Route path="/admin/forgot_password" element={<Forgot />} />
          <Route path="/admin/reset-password/" element={<ResetPwd />} />
          <Route path="/admin/admin_transaction" element={<UserWithdralRequest />} />
          <Route path="/admin/all_transaction" element={<AllTransaction />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
