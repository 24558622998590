import React, { useState } from "react";
import { Form, Modal, Input, Tooltip, Button, Slider } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import "./WithdrawModal.css";
import Sui from "../../../Assets/Svg/Sui.svg";
import { useWallet } from "@suiet/wallet-kit";
import { withdrawCoinWithAccountCap } from "./Withdraw";
import toast from "react-hot-toast";
import { BaseUrl, getIndexAssetData } from "../../Common/Apis/Apis";
import axios from "axios";

const Withdraw = ({
  tokenLogo,
  tokenName,
  balance,
  CoinObjectId,
  balances,
  stakes,
  handleReload,
  assetsBal,
  withdrawReload,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [amount, setAmount] = useState("");
  const [sliderValue, setSliderValue] = useState(0);
  const [health, setHealth] = useState("-");
  const wallet = useWallet();

  const suiUsdDebt = stakes?.SUI?.borrowBalance * assetsBal[0]?.price;
  const usdcUsdDebt = stakes?.USDC?.borrowBalance * assetsBal[1]?.price;
  const wethUsdDebt = stakes?.WETH?.borrowBalance * assetsBal[2]?.price;
  const totalUsdDebt = suiUsdDebt + usdcUsdDebt + wethUsdDebt;

  const suiUsdSupply = stakes?.SUI?.supplyBalance * assetsBal[0]?.price;
  const usdcUsdSupply = stakes?.USDC?.supplyBalance * assetsBal[1]?.price;
  const wethUsdSupply = stakes?.WETH?.supplyBalance * assetsBal[2]?.price;

  const suiLiqThreshold = 0.8;
  const usdcLiqThreshold = 0.85;
  const wethLiqThreshold = 0.8;

  const updateHealthFactor = (value) => {
    var newHF = 0;
    if (tokenName == "SUI") {
      const newSuiUsdSupply = suiUsdSupply - value * assetsBal[0]?.price;
      newHF = ((newSuiUsdSupply * suiLiqThreshold) + (usdcUsdSupply * usdcLiqThreshold) + (wethUsdSupply * wethLiqThreshold))/totalUsdDebt;
    }
    if (tokenName == "USDC") {
      const newUsdcUsdSupply = usdcUsdSupply - value * assetsBal[1]?.price;
      newHF = ((suiUsdSupply * suiLiqThreshold) + (newUsdcUsdSupply * usdcLiqThreshold) + (wethUsdSupply * wethLiqThreshold))/totalUsdDebt;
    }
    if (tokenName == "WETH") {
      const newWethUsdSupply = wethUsdSupply - value * assetsBal[2]?.price;
      newHF = ((suiUsdSupply * suiLiqThreshold) + (usdcUsdSupply * usdcLiqThreshold) + (newWethUsdSupply * wethLiqThreshold))/totalUsdDebt;
    }
    var healthElement = document.getElementById("new_health_factor_withdraw_"+tokenName);
    if (parseFloat(newHF) <= 100) {
      healthElement.textContent = parseFloat(newHF).toFixed(2);
    }
    else {
      healthElement.textContent = "Infinity";
    }
  };

  const UsdtoSui = totalUsdDebt / assetsBal[0]?.price;
  const UsdtoUsdc = totalUsdDebt / assetsBal[1]?.price;
  const UsdtoWeth = totalUsdDebt / assetsBal[2]?.price;

  const borrowAmt =
    tokenName === "SUI"
      ? UsdtoSui
      : tokenName === "USDC"
      ? UsdtoUsdc
      : UsdtoWeth;

  const handleClick = async () => {
    if (!wallet) {
      toast.error("Please connect your Sui wallet first.");
      return;
    }
    if (amount > balance - borrowAmt) {
      toast.error("Entered Value is more then Navi Supplied Balance.");
      return;
    }
    try {
      const result = await withdrawCoinWithAccountCap(
        CoinObjectId,
        wallet,
        amount,
        tokenName
      );
      handleCancel();
      handleReload();
      withdrawReload();
    } catch (error) {
      console.error("Transaction failed:", error);
    }
  };

  var newHealthFactorId = "new_health_factor_withdraw_"+tokenName;
  const showModal = () => {
    setIsModalOpen(true);
    setAmount("");
    fetchHealth();
    var newHF = document.getElementById(newHealthFactorId);
    if (newHF != null) {
      newHF.textContent = "";
    }
    //setSliderValue(0);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };

  //const handleSliderChange = (value) => {
    //setSliderValue(value);
    //const newAmount = (value / 100) * balance;
    //setAmount(newAmount);
  //};

  const handleMaxClick = () => {
    let value = balance - borrowAmt
    setAmount(value);
    updateHealthFactor(value);
    //setSliderValue(100);
  };

  const handleInputChange = (e) => {
    let value = e.target.value;
    if (isNaN(value)) {
      value = 0;
    }
    setAmount(value);
    updateHealthFactor(value);
    //setSliderValue((value / (balance - borrowAmt)) * 100);
  };

  const mode = process.env.REACT_APP_MODE;
  const naviAddAccountCap =
    mode === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getHealthFactor?key=${naviAddAccountCap}`
      );
      setHealth(response?.data?.data?.health.toFixed(2) || "0.00");
    } catch (err) {
      console.error(err.message);
      setHealth("0.00");
    }
  };

  const marks = {
    0: "0",
    25: "25",
    50: "50",
    75: "75",
    100: "100",
  };

  return (
    <>
      <span className="me-2" onClick={showModal}>
        Navi Withdraw
      </span>
      <Modal
        title={`${tokenName} Navi Withdraw `}
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        className="supply-modal"
      >
        <Form layout="vertical">
          <Form.Item>
            <Tooltip title="Total amount of assets to withdraw">
              <div className="d-flex align-items-center">
                Amount <MdOutlineInfo className="ms-1" />
              </div>
            </Tooltip>
            <div className="amount-input">
              <div className="p-2 amount-info d-flex justify-content-end">
                {/* <span>$0</span> */}
                <span>{tokenName} Available: {(balance - borrowAmt)?.toFixed(4)}</span>
              </div>
              <div className="d-flex">
                <Input
                  placeholder="0.00"
                  className="amount-value"
                  type="number"
                  step="any"
                  min={0}
                  max={balance}
                  value={amount} // Bind input value to state
                  onChange={handleInputChange} // Update state on input change
                />
                <button className="max-btn" onClick={handleMaxClick}>
                  MAX
                </button>
                <div className="mx-2 d-flex align-items-center">
                  <img
                    src={tokenLogo}
                    alt=""
                    className="img-fluid me-1"
                    width={18}
                  />
                  <strong>{tokenName}</strong>
                </div>
              </div>
              <div className="mt-2 d-flex align-items-center"></div>
            </div>
          </Form.Item>
          {/*<Slider
            marks={marks}
            value={sliderValue}
            onChange={handleSliderChange}
          />*/}
          <Form.Item>
            <span>Transaction Overview</span>
            <div className="transaction-overview">
              <div className="mb-2 d-flex justify-content-between">
                <span>Current Health Factor</span>
                <span>{health}</span>
              </div>
              <div className="mb-2 d-flex justify-content-between">
                <span>Withdraw Amount</span>
                <span>{amount}</span>
              </div>
              <div className="d-flex justify-content-between">
                <span>New Health Factor</span>
                <span id={newHealthFactorId}></span>
              </div>
            </div>
          </Form.Item>
          <Button
            size="large"
            block
            className="supplyBtn"
            onClick={handleClick}
            disabled={amount <= 0 || amount > parseFloat(balance)}
          >
            {amount <= 0 ? "Enter Amount" : `Withdraw ${tokenName}`}
          </Button>
        </Form>
      </Modal>
    </>
  );
};

export default Withdraw;
