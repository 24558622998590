import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";

export async function Reward(wallet, calculatedBalance,balance) {
  try {
    const config = await getConfig();
    const txb = new Transaction();
    const incentiveFundsPool =
      "e2b5ada45273676e0da8ae10f8fe079a7cec3d0f59187d3d20b1549c275b07ea";
    const walletAddress = wallet.account.address;

    if (calculatedBalance > 255) {
      throw new Error("Max Reward Claim 255");
    }
    const ProFundsPoolInfo = {
      f975bc2d4cca10e3ace8887e20afd77b46c383b4465eac694c4688344955dea4: {
        coinType: "0x2::sui::SUI",
        oracleId: 0,
      },
      e2b5ada45273676e0da8ae10f8fe079a7cec3d0f59187d3d20b1549c275b07ea: {
        coinType:
          "0x549e8b69270defbfafd4f94e17ec44cdbdd99820b33bda2278dea3b9a32d3f55::cert::CERT",
        oracleId: 5,
      },
      a20e18085ce04be8aa722fbe85423f1ad6b1ae3b1be81ffac00a30f1d6d6ab51: {
        coinType:
          "0xbde4ba4c2e274a60ce15c1cfff9e5c42e41654ac8b6d906a57efa4bd3c29f47d::hasui::HASUI",
        oracleId: 6,
      },
      "9dae0cf104a193217904f88a48ce2cf0221e8cd9073878edd05101d6b771fa09": {
        coinType:
          "0xa99b8952d4f7d947ea77fe0ecdcc9e5fc0bcab2841d6e2a5aa00c3044e5544b5::navx::NAVX",
        oracleId: 7,
      },
    };

    if (!ProFundsPoolInfo[incentiveFundsPool]) {
      throw new Error("Invalid incentive funds pool ID.");
    }

    const [ret] = txb.moveCall({
      target: `${config.FairmilePackage}::suidollar::claimRewards`,
      arguments: [
        txb.object(config.myStruct), // me: &MyStruct,
        txb.object(config.ClockID), // clock: &Clock,
        txb.object(config.IncentiveV2), // incentive: &mut Incentive,
        txb.object(`0x${incentiveFundsPool}`), // funds_pool: &mut IncentiveFundsPool<CoinType>,
        txb.object(config.StorageId), // storage: &mut Storage,
        txb.pure.u8(calculatedBalance), // asset: u8,
        txb.pure.u8(1) 
    ],
      typeArguments: [ProFundsPoolInfo[incentiveFundsPool].coinType],
    });
     const [coin] = txb.moveCall({
        target: `0x2::coin::from_balance`,
        arguments: [txb.object(ret)],
        typeArguments: [pool.vSui.type],
    });
    txb.transferObjects([coin], walletAddress);
    // txb.setGasBudget(1000000);

    const result = await wallet.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });
    console.log(result);
    toast.success("Transaction successful");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Transaction rejected by user"
        : error.message || "Transaction failed"
    );
    console.error("Error executing transaction:", error);
  }
}
