import React, { useEffect } from "react";
import { Button, Form, Input, Modal, Tooltip, message } from "antd";
import { FaVault } from "react-icons/fa6";
import axios from "axios";
import { BaseUrl } from "../Common/Apis/Apis";
import toast from "react-hot-toast";

const VaultDetails = () => {
  const [open, setOpen] = React.useState(false);
  const [loading, setLoading] = React.useState(true);
  const [form] = Form.useForm();
  const apiToken = localStorage?.getItem("token");

  useEffect(() => {
    const fetchVaultDetails = async () => {
      try {
        const response = await axios.get(
          `${BaseUrl}admin/getVaultStatsDetails`,
          {
            headers: {
              accept: "application/json",
              Authorization: apiToken.replace(/^"|"$/g, ""),
            },
          }
        );
        const data = response?.data?.data[0];
        form.setFieldsValue({
          withdrawal_processsing: data.withdrawal_processsing,
          vault_capacity: data.vault_capacity,
          performance_fee: data.performance_fee,
          DepositFee: data.user_deposit_value,
        });
        setLoading(false);
      } catch (error) {
        setOpen(false);
        console.error("Error fetching vault details", error);
        toast.error("Failed to load vault details");
        setLoading(false);
      }
    };

    if (open) {
      setLoading(true);
      fetchVaultDetails();
    }
  }, [open, form]);

  const showLoading = () => {
    setOpen(true);
  };

  const handleSave = async (values) => {
    const withdrawal_processsing = String(values.withdrawal_processsing);
    const vault_capacity = String(values.vault_capacity);
    const performance_fee = String(values.performance_fee);
    const user_deposit_value = String(values.DepositFee);
    try {
      const response = await axios.post(
        `${BaseUrl}admin/saveVaultStatsDetails`,
        {
          withdrawal_processsing,
          vault_capacity,
          performance_fee,
          user_deposit_value,
        },
        {
          headers: {
            Authorization: apiToken.replace(/^"|"$/g, ""),
          },
        }
      );
      setOpen(false);
      toast.success("Vault details saved successfully");
      console.log(response.data);
    } catch (error) {
      console.error("Error saving vault details", error);
      message.error("Failed to save vault details");
    }
  };

  return (
    <>
      <Tooltip title={"Update Vault Detail Data"}>
        <FaVault 
          style={{ cursor: "pointer" }}
          onClick={showLoading} 
        />
      </Tooltip>
      <Modal
        title={"Vault Details"}
        footer={null}
        loading={loading}
        open={open}
        onCancel={() => setOpen(false)}
      >
        <Form layout="vertical" form={form} onFinish={handleSave}>
          <Form.Item
            label="Withdrawal Processing Time"
            name="withdrawal_processsing"
            rules={[
              {
                required: true,
                message: "Please enter withdrawal processing time",
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Vault Capacity"
            name="vault_capacity"
            rules={[{ required: true, message: "Please enter vault capacity" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Performance Fee"
            name="performance_fee"
            rules={[
              { required: true, message: "Please enter performance fee" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Deposit Fee"
            name="DepositFee"
            rules={[
              { required: true, message: "Please enter user deposit value" },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item className="text-center">
            <Button
              type="default"
              htmlType="submit"
              className="supplyBtn w-100"
            >
              Save
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default VaultDetails;
