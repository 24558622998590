import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import { toast } from "react-hot-toast";
import axios from "axios";
import { apiToken, BaseUrl } from "../../Common/Apis/Apis";

export async function UserdepositContract(
  wallet,
  calculatedBalance,
  Object_Id,
  netWorth,
  userbalances,
  tokenName
) {
  try {
    const config = await getConfig();
    const txb = new Transaction();
    const amountInNanoUnits = Math.floor(calculatedBalance * 1e6);

    if (Object_Id?.length > 0) {
      let baseObj = Object_Id[0].coinObjectId;

      for (let i = 1; i < Object_Id.length; i++) {
        txb.mergeCoins(baseObj, [Object_Id[i].coinObjectId]);
      }
    }

    const tokenDetails = {
      wUSDC: {
        poolId: pool.USDC.poolId,
        assetId: pool.USDC.assetId,
        type: pool.USDC.type,
      },
      USDC: {
        poolId: pool.USDC.poolId,
        assetId: pool.NUSDC.assetId,
        type: pool.NUSDC.type,
      },
    };
    const selectedToken = tokenDetails[tokenName];

    if (Object_Id.length > 0) {
      const [coinObject] = txb.splitCoins(txb.object(Object_Id[0].coinObjectId), [
        amountInNanoUnits,
      ]);
if(tokenName === "wUSDC"){

      txb.moveCall({
        target: `${config.FairmilePackage}::suidollar::userDeposit`,
        arguments: [
          txb.object(config.usdcTreasury),
          coinObject, 
          txb.object(process.env?.REACT_APP_DEV_FEE_CONFIG),
        ],
        typeArguments: [selectedToken.type],
      });
    }else{
      txb.moveCall({
        target: `${config.FairmilePackage}::suidollar::userDeposit`,
        arguments: [
          txb.object(config.usdcNativeTreasury),
          coinObject, 
          txb.object(process.env?.REACT_APP_DEV_FEE_CONFIG),
        ],
        typeArguments: [selectedToken.type],
      });
    }

    } else {
      throw new Error("No valid coinObjectId found for splitting coins.");
    }
    const result = await wallet.signAndExecuteTransactionBlock({
      transactionBlock: txb,
    });
    console.log(result, "result");
    const apiResponse = await axios.post(
      BaseUrl + "client/depositTransaction",
      {
        walletAddress: wallet.address,
        totalUserPosition: userbalances / 1e6,
        contractNetWorth: netWorth / 1e6,
        usdcAmount: amountInNanoUnits / 1e6,
        TransactionHash: result.digest,
      },
      {
        headers: {
          Authorization: apiToken?.token,
          "Content-Type": "application/json",
        },
      }
    );
    toast.success("Transaction successful!");
  } catch (error) {
    toast.error(
      error.message === "[WALLET.SIGN_TX_ERROR] Rejected from user"
        ? "Rejected from user"
        : error.message || "Transaction failed!"
    );
    console.error("Error executing transaction:", error);
  }
}
