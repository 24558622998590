import { Transaction } from "@mysten/sui/transactions";
import { getConfig, pool } from "../../Common/Address/Address";
import toast from "react-hot-toast";

export async function withdrawCoinWithAccountCap(
  CoinObjectId,
  wallet,
  amount,
  tokenName
) {
  const config = await getConfig();
  const walletAddress = wallet.account.address;
  const amountInNanoUnits =
    tokenName === "SUI"
      ? Math.floor((amount-(amount*0.001)) * 1e9)
      : tokenName === "USDC"
      ? Math.floor((amount-(amount*0.001)) * 1e6)
      : Math.floor((amount-(amount*0.001)) * 1e8);

  const tokenDetails = {
    SUI: {
      poolId: pool.Sui.poolId,
      assetId: pool.Sui.assetId,
      type: pool.Sui.type,
      coinObjectId: CoinObjectId?.SuiCoinObjectId || "",
    },
    wUSDC: {
      poolId: pool.USDC.poolId,
      assetId: pool.USDC.assetId,
      type: pool.USDC.type,
      coinObjectId: CoinObjectId?.UsdcCoinObjectId || "",
    },
    WETH: {
      poolId: pool.WETH.poolId,
      assetId: pool.WETH.assetId,
      type: pool.WETH.type,
      coinObjectId: CoinObjectId?.WethCoinObjectId || "",
    },
  };

  const selectedToken = tokenDetails[tokenName];
  const txb = new Transaction();
  if (tokenName === "SUI") {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::suidollar::withdrawNavi`,
      arguments: [
        txb.object(config.suiTreasury), // AccountCap Object
        txb.object(config.myStruct), // AccountCap Object
        txb.sharedObjectRef({
          objectId: config.ClockID,
          initialSharedVersion: 1,
          mutable: false,
        }), // Clock object ID
        txb.object(config.PriceOracle), // Oracle Price
        txb.object(config.StorageId), // Storage object ID
        txb.object(selectedToken.poolId), // Pool ID of the asset
        txb.pure.u8(selectedToken.assetId), // Asset ID in the protocol
        txb.pure.u64(amountInNanoUnits), // Amount to withdraw
        txb.object(config.Incentive), // Incentive object
        txb.object(config.IncentiveV2), // Incentive object v2
      ],
      typeArguments: [selectedToken.type],
    });
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else if (tokenName === "wUSDC") {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::suidollar::withdrawNavi`,
      arguments: [
        txb.object(config.usdcTreasury), // AccountCap Object
        txb.object(config.myStruct), // AccountCap Object
        txb.sharedObjectRef({
          objectId: config.ClockID,
          initialSharedVersion: 1,
          mutable: false,
        }), // Clock object ID
        txb.object(config.PriceOracle), // Oracle Price
        txb.object(config.StorageId), // Storage object ID
        txb.object(selectedToken.poolId), // Pool ID of the asset
        txb.pure.u8(selectedToken.assetId), // Asset ID in the protocol
        txb.pure.u64(amountInNanoUnits), // Amount to withdraw
        txb.object(config.Incentive), // Incentive object
        txb.object(config.IncentiveV2), // Incentive object v2
      ],
      typeArguments: [selectedToken.type],
    });
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  } else {
    const txb = new Transaction();
    txb.moveCall({
      target: `${config.FairmilePackage}::suidollar::withdrawNavi`,
      arguments: [
        txb.object(config.wethTreasury), // AccountCap Object
        txb.object(config.myStruct), // AccountCap Object
        txb.sharedObjectRef({
          objectId: config.ClockID,
          initialSharedVersion: 1,
          mutable: false,
        }), // Clock object ID
        txb.object(config.PriceOracle), // Oracle Price
        txb.object(config.StorageId), // Storage object ID
        txb.object(selectedToken.poolId), // Pool ID of the asset
        txb.pure.u8(selectedToken.assetId), // Asset ID in the protocol
        txb.pure.u64(amountInNanoUnits), // Amount to withdraw
        txb.object(config.Incentive), // Incentive object
        txb.object(config.IncentiveV2), // Incentive object v2
      ],
      typeArguments: [selectedToken.type],
    });
    try {
      const result = await wallet.signAndExecuteTransactionBlock({
        transactionBlock: txb,
      });
      toast.success("Transaction successful");
    } catch (error) {
      toast.error(
        error?.message === "[WALLET.SIGN_TX_ERROR] Rejected by user"
          ? "Rejected by user"
          : error?.message || "Transaction failed"
      );
      console.error("Error executing transaction:", error);
    }
  }
}
