import React, { useState, useEffect } from "react";
import { Layout } from "antd";
import { Tab, Tabs } from "react-bootstrap";
import AssetsDetails from "../../Components/AssetDetail/AssetsDetails";
import "./Market.css";
import Navi from "../Navi/Navi";
import axios from "axios";
import { BaseUrl } from "../../Components/Common/Apis/Apis";
import { useNavigate } from "react-router-dom";

function Market() {
  const [activeTab, setActiveTab] = useState("3");
  const [netWorth, setNetWorth] = useState("0.00");
  const [assetsUsdValue, SetAssetsUsdValue] = useState("0.00");
  const [supplyedUsd, SetSupplyedUsd] = useState("0.00");
  const [borrowUsd, SetBorrowUsd] = useState("0.00");
  const [UsdToken, SetUsdToken] = useState("0.00");
  const [health, setHealth] = useState("-");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (activeTab === "3" && !token) {
      navigate("/admin/login");
    }
  }, [activeTab, navigate]);

  useEffect(() => {
    const savedActiveTab = localStorage.getItem("activeTab");
    if (savedActiveTab) {
      setActiveTab(savedActiveTab);
    }
  }, []);

  const handleTabSelect = (key) => {
    setActiveTab(key);
    localStorage.setItem("activeTab", key);
  };

  const mode = process.env.REACT_APP_MODE;
  const naviAddAccountCap =
    mode === "DEV"
      ? process.env.REACT_APP_NAVI_ACC_DEV
      : process.env.REACT_APP_NAVI_ACC_PROD;

  const fetchHealth = async () => {
    try {
      const response = await axios.get(
        `${BaseUrl}client/getHealthFactor?key=${naviAddAccountCap}`
      );
      setHealth(response?.data?.data?.health || "0.00");
    } catch (err) {
      console.error(err.message);
      setHealth("0.00");
    }
  };

  useEffect(() => {
    fetchHealth();
  }, []);

  return (
    <div>
      <Tabs
        activeKey={activeTab}
        onSelect={handleTabSelect}
        id="controlled-tab-example"
        className="mt-4 justify-content-center"
      >
        {/* <Tab eventKey="1" title="BlueFin">
          <div className="d-flex justify-content-center">
            <img src={ComingSoon} alt="" className="img-fluid CommingSoon" width={600} />
          </div>
        </Tab>
        <Tab eventKey="2" title="Cetus">
          <div className="d-flex justify-content-center">
            <img src={ComingSoon} alt="" className="img-fluid CommingSoon" width={600} />
          </div>
        </Tab> */}
        <Tab eventKey="3" title="Navi" className="mx-3 mt-3 mx-md-5">
          {activeTab === "3" && (
            <AssetsDetails
              netWorth={netWorth}
              assetsUsdValue={assetsUsdValue}
              supplyedUsd={supplyedUsd}
              borrowUsd={borrowUsd}
              UsdToken={UsdToken}
              health={health}
            />
          )}
          <Layout style={{ minHeight: "100vh" }} >
            <Navi
              setNetWorth={setNetWorth}
              SetAssetsUsdValue={SetAssetsUsdValue}
              SetSupplyedUsd={SetSupplyedUsd}
              SetBorrowUsd={SetBorrowUsd}
              SetUsdToken={SetUsdToken}
              fetchHealth={() => fetchHealth()}
            />
          </Layout>
        </Tab>
      </Tabs>
    </div>
  );
}

export default Market;
