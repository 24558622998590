import React from "react";
import { Card } from "react-bootstrap";
import { Button, Form, Input, message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import Logo from "../../../Assets/Images/LogoDark.png";
import { BaseUrl } from "../../Common/Apis/Apis";
import toast from "react-hot-toast";

const ResetPwd = () => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const location = useLocation();
  
  // Function to get query parameter from URL
  const getQueryParam = (name) => {
    const params = new URLSearchParams(location.search);
    return params.get(name);
  };

  // Extract resetId from query parameters
  const resetId = getQueryParam("resetId");

  const handleSubmit = async (values) => {
    const { newPassword, confirmPassword } = values;

    if (newPassword !== confirmPassword) {
      message.error("Passwords do not match.");
      return;
    }

    try {
      const response = await axios.post(
        `${BaseUrl}admin/resetPassword`,
        {
          password: newPassword, 
        },
        {
          headers: {
            accept: "application/json",
            Authorization: resetId,
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Password reset successfully!");
      navigate("/login");
      console.log(response,"response");
      
    } catch (error) {
      console.error("Error:", error.response ? error.response.data : error.message);
      message.error("Failed to reset password. Invalid token or request.");
    }
  };

  return (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <Card style={{ width: "40%", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.3)" }}>
        <Card.Body>
          <div className="d-flex justify-content-center">
            <img src={Logo} alt="Logo" className="mb-4 img-fluid" width={150} />
          </div>
            <Form form={form} layout="vertical" onFinish={handleSubmit}>
              <Form.Item
                name="newPassword"
                label="New Password"
                rules={[
                  { required: true, message: "Please enter your new password!" },
                ]}
              >
                <Input.Password placeholder="Enter new password" />
              </Form.Item>

              <Form.Item
                name="confirmPassword"
                label="Confirm Password"
                rules={[
                  { required: true, message: "Please confirm your password!" },
                ]}
              >
                <Input.Password placeholder="Confirm your password" />
              </Form.Item>

              <Form.Item>
                <Button type="primary" htmlType="submit" block>
                  Reset Password
                </Button>
              </Form.Item>
            </Form>
        </Card.Body>
      </Card>
    </div>
  );
};

export default ResetPwd;
