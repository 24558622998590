import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card, Badge } from "react-bootstrap";
import { Flex, Progress, Tooltip } from "antd";
import { MdOutlineInfo } from "react-icons/md";
import { getConfig } from "../Common/Address/Address";
import { IoMdCopy } from "react-icons/io";
import toast from "react-hot-toast";
import { useWallet } from "@suiet/wallet-kit";
import { BaseUrl, Suivision } from "../Common/Apis/Apis";
import { GrDocumentUpdate } from "react-icons/gr";
import axios from "axios";
import Swal from "sweetalert2";
import FormAssetdetail from "../Modal/FormAssetsDetail/FormAssetsDetail";
import { FaInfinity } from "react-icons/fa";
import { exchangeInfo, marketData } from "../Common/Apis/Apis";
import VaultDetails from "./VaultDetails";

function AssetsDetails({
  netWorth,
  assetsUsdValue,
  supplyedUsd,
  borrowUsd,
  UsdToken,
  health,
}) {
  const [config, setConfig] = useState(null);
  const [vSui, setVSui] = useState();
  const totalUsd = assetsUsdValue + supplyedUsd;
  const usedSupplyed = supplyedUsd;
  const usedPercentage = (usedSupplyed / totalUsd) * 100;
  const ProcessPercent = Math.round(usedPercentage || 0 || "0");
  const wallet = useWallet();
  const [ethMarketData, setETHMarketData] = useState("0.0");
  const [suiMarketData, setSuiMarketData] = useState("0.0");
  const [ethGetexchangeinfo, setETHGetexchangeinfo] = useState();
  const [suiGetexchangeinfo, setSuiGetexchangeinfo] = useState();
  const [margin, setMargin] = useState("0.0");
  const [positionPrice, setPositionPrice] = useState("0.0");
  const [position_Size, setposition_Size] = useState("0.0");
  const [marginEth, setMarginEth] = useState("0.0");
  const [positionPriceEth, setPositionPriceEth] = useState("0.0");
  const [position_SizeEth, setposition_SizeEth] = useState("0.0");
  const [allPosition, setAllPosition] = useState("0.0");
  const [loading, setLoading] = useState(false);

  const fetchMarketData = async (symbol, setData) => {
    try {
      const options = {
        method: "GET",
        url: marketData,
        params: { symbol },
        headers: { accept: "application/json" },
      };

      const response = await axios.request(options);
      setData(response.data);
    } catch (error) {
      console.error(error);
    }
  };
  const fetchExchangeInfo = async (symbol, setData) => {
    setLoading(true)
    try {
      const options = {
        method: "GET",
        url: exchangeInfo,
        params: { symbol },
        headers: { accept: "application/json" },
      };

      const response = await axios.request(options);
      setData(response.data);
    setLoading(false)
    } catch (error) {
      console.error(error);
    setLoading(false)
    }
  };

  const fetchRewardData = async () => {
    setLoading(true)
    try {
      const response = await axios.get(BaseUrl + `client/edgesuivision`, {
        headers: {
          accept: "application/json, text/plain, */*",
        },
      });
      setVSui(response?.data?.data?.result?.rewards[0]?.value);

    } catch (error) {
      console.error("Error fetching data:", error);
    }finally{
    setLoading(false)
    }
  };
  const AllUserPosition = async () => {
    setLoading(true)
    const apiToken = localStorage?.getItem("token");
    try {
      const response = await axios.get(BaseUrl + `admin/getAllUserPosition`, {
        headers: {
          Authorization: apiToken.replace(/^"|"$/g, ""),
          accept: "application/json, text/plain, */*",
        },
      });
      setAllPosition(response?.data?.data);
      setLoading(false)
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false)
    }
  };

  useEffect(() => {
    fetchMarketData("ETH-PERP", setETHMarketData);
    fetchMarketData("SUI-PERP", setSuiMarketData);
    fetchExchangeInfo("ETH-PERP", setETHGetexchangeinfo);
    fetchExchangeInfo("SUI-PERP", setSuiGetexchangeinfo);
    fetchRewardData();
    AllUserPosition();
  }, []);

  //SUI PERP
  const SuiFundingRate = (
    (suiMarketData?.lastFundingRate / 1e18) *
    100 *
    (24 * 365)
  )?.toFixed(2);
  const SuiOraclePrice = (suiMarketData?.oraclePrice / 1e18)?.toFixed(4);
  const SuiMMR = suiGetexchangeinfo?.maintenanceMarginReq / 1e18;
  const suiPositionEntryPriceFloat = parseFloat(positionPrice || "0.0");
  const suiPositionSizeFloat = parseFloat(position_Size || "0.0");
  const suiMarginFloat = parseFloat(margin || "0.0");

  const SuiPositionPnL = (
    (suiPositionEntryPriceFloat - SuiOraclePrice) *
    suiPositionSizeFloat
  ).toFixed(2);
  const SuiLiquidationPrice = (
    (suiMarginFloat + suiPositionSizeFloat * suiPositionEntryPriceFloat) /
    (suiPositionSizeFloat * (1 + SuiMMR))
  ).toFixed(4);
  const distanceFromSuiLiq = (
    ((SuiLiquidationPrice - SuiOraclePrice) / SuiOraclePrice) *
    100
  ).toFixed(2);
  const SuiLeverage = (
    (suiPositionSizeFloat * SuiOraclePrice) /
    suiMarginFloat
  ).toFixed(2);

  // ETH PERP
  const EthFundingRate = (
    (ethMarketData?.lastFundingRate / 1e18) *
    100 *
    (24 * 365)
  )?.toFixed(2);
  const EthOraclePrice = (ethMarketData?.oraclePrice / 1e18)?.toFixed(2);
  const EthMMR = ethGetexchangeinfo?.maintenanceMarginReq / 1e18;

  const ethPositionEntryPriceFloat = parseFloat(positionPriceEth || "0.0");
  const ethPositionSizeFloat = parseFloat(position_SizeEth || "0.0");
  const ethMarginFloat = parseFloat(marginEth || "0.0");

  const EthPositionPnL = (
    (ethPositionEntryPriceFloat - EthOraclePrice) *
    ethPositionSizeFloat
  ).toFixed(2);
  const EthLiquidationPrice = (
    (ethMarginFloat + ethPositionSizeFloat * ethPositionEntryPriceFloat) /
    (ethPositionSizeFloat * (1 + EthMMR))
  ).toFixed(2);
  const distanceFromEthLiq = (
    ((EthLiquidationPrice - EthOraclePrice) / EthOraclePrice) *
    100
  ).toFixed(2);
  const EthLeverage = (
    (ethPositionSizeFloat * EthOraclePrice) /
    ethMarginFloat
  ).toFixed(2);

  useEffect(() => {
    async function fetchConfig() {
      const configData = await getConfig();
      setConfig(configData);
    }
    fetchConfig();
  }, []);

  const conicColors = {
    "0%": "#00264d",
    "50%": "#4DA2FF",
    "100%": "#0EC3A4",
  };

  const formatWithCommas = (amount) => {
    return amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };

  const naviAccountCap = `${config?.naviAccountCap?.slice(
    0,
    4
  )}...${config?.naviAccountCap.slice(-4)}`;

  const handleCopy = () => {
    navigator.clipboard.writeText(config?.naviAccountCap || "").then(() => {
      toast.success("AccountCap address copied");
    });
  };

  function sliceAfterDot(num, digits) {
    const numStr = num.toString();
    const [integerPart, decimalPart] = numStr?.split(".");
    const slicedDecimal = decimalPart?.slice(0, digits);
    return `${integerPart}.${slicedDecimal || "-"}`;
  }

  const HealthFactor = sliceAfterDot(health, 2);

  const assetsssss = Object.values(UsdToken)
    .filter((info) => ["USDC", "SUI", "WETH"].includes(info.symbol))
    .map((info) => ({
      asset: info.symbol,
      info,
    }));

  const suiPrice = assetsssss[0]?.info?.price || "0.0";
  const usdcPrice = assetsssss[1]?.info?.price || "0.0";
  const wethPrice = assetsssss[2]?.info?.price || "0.0";
  // const roundedSuiPrice = parseFloat(suiPrice).toFixed(4);
  // const roundedWethPrice = parseFloat(wethPrice).toFixed(2);
  const suiUsdSupply = borrowUsd?.SUI?.supplyBalance * suiPrice;
  const usdcUsdSupply = borrowUsd?.USDC?.supplyBalance * usdcPrice;
  const wethUsdSupply = borrowUsd?.WETH?.supplyBalance * wethPrice;
  const totalUsdSupply = suiUsdSupply + usdcUsdSupply + wethUsdSupply;
  const suiUsdDebt = borrowUsd?.SUI?.borrowBalance * suiPrice;
  const usdcUsdDebt = borrowUsd?.USDC?.borrowBalance * usdcPrice;
  const wethUsdDebt = borrowUsd?.WETH?.borrowBalance * wethPrice;
  const totalUsdDebt =
    (suiUsdDebt || 0) + (usdcUsdDebt || 0) + (wethUsdDebt || 0);

  const unclaimedRewards = parseFloat(vSui) * suiPrice;

  const naviNetAssets = totalUsdSupply - totalUsdDebt + unclaimedRewards;
  const vaultNetWorth =
    naviNetAssets +
    assetsUsdValue +
    suiMarginFloat +
    parseFloat(SuiPositionPnL) +
    ethMarginFloat +
    parseFloat(EthPositionPnL);

  const SuiDoller = vaultNetWorth / allPosition;
  
  const suiSupplyAPY =
    (parseFloat(assetsssss[0]?.info?.supply_rate || 0) +
      parseFloat(assetsssss[0]?.info?.boosted || 0)) /
    100;
  const usdcSupplyAPY =
    (parseFloat(assetsssss[1]?.info?.supply_rate || 0) +
      parseFloat(assetsssss[1]?.info?.boosted || 0)) /
    100;
  const wethSupplyAPY =
    (parseFloat(assetsssss[2]?.info?.supply_rate || 0) +
      parseFloat(assetsssss[2]?.info?.boosted || 0)) /
    100;

  const suiBorrowAPY =
    (parseFloat(assetsssss[0]?.info?.borrow_rate || 0) -
      parseFloat(assetsssss[0]?.info?.borrow_reward_apy || 0)) /
    100;
  const usdcBorrowAPY =
    (parseFloat(assetsssss[1]?.info?.borrow_rate || 0) -
      parseFloat(assetsssss[1]?.info?.borrow_reward_apy || 0)) /
    100;
  const wethBorrowAPY =
    (parseFloat(assetsssss[2]?.info?.borrow_rate || 0) -
      parseFloat(assetsssss[2]?.info?.borrow_reward_apy || 0)) /
    100;

  const naviInterestIncome =
    suiUsdSupply * suiSupplyAPY +
    usdcUsdSupply * usdcSupplyAPY +
    wethUsdSupply * wethSupplyAPY;
  const naviInterestExpense =
    suiUsdDebt * suiBorrowAPY +
    usdcUsdDebt * usdcBorrowAPY +
    wethUsdDebt * wethBorrowAPY;
  const naviNetIncome = naviInterestIncome - naviInterestExpense;

  const suiFundingIncome =
    suiPositionSizeFloat * SuiOraclePrice * (SuiFundingRate / 100);
  const ethFundingIncome =
    ethPositionSizeFloat * EthOraclePrice * (EthFundingRate / 100);

  const totalNetIncome = naviNetIncome + suiFundingIncome + ethFundingIncome;

  const vaultAPY = (totalNetIncome / vaultNetWorth) * 100;

  const handleUpdate = async () => {
    const result = await Swal.fire({
      title: "Are you sure?",
      text: "Do you want to update the data?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, update it!",
    });

    if (result.isConfirmed) {
      setLoading(true);
      const apiToken = localStorage?.getItem("token");
      try {
        const response = await axios.post(
          `${BaseUrl}admin/saveGlobalDetails`,
          {
            apy: vaultAPY.toString() || "0.0",
            net_worth: vaultNetWorth?.toString() || "0.0",
            exchange_rate_value: SuiDoller?.toString() || "1",
            total_value_locked: allPosition?.toString() || "0.0",
          },
          {
            headers: {
              accept: "application/json",
              Authorization: apiToken.replace(/^"|"$/g, ""),
            },
          }
        );
        toast.success(response?.data.message);
      } catch (error) {
        toast.error(error?.response?.data?.error || "Something went wrong.");
      } finally {
        setLoading(false);
      }
    }
  };

  return (
    <div>
      <Card>
        <div
          className="flex-wrap d-flex"
          style={{ borderBottom: "1px solid #f5f5f5", padding: "10px 20px" }}
        >
          <div className="flex-wrap d-flex align-items-center ms-auto me-4 justify-content-between">
            <div className="px-4 rounded-pill">
              <span className="p-1 text-center d-flex align-items-center">
                <h6 className="mb-0">stSUSD Exchange Rate:&nbsp;</h6>
                <strong>
                  {loading ? ("--") : (
                    `$${formatWithCommas(
                      Number(SuiDoller)?.toFixed(2) || "0.0"
                    )}`
                  )}
                </strong>
              </span>
            </div>
            <div className="px-4 rounded-pill">
              <span className="p-1 text-center d-flex align-items-center">
                <h6 className="mb-0">Total User Position:&nbsp;</h6>
                <strong>
                  {loading ? "--" : (
                    formatWithCommas(Number(allPosition)?.toFixed(2) || "0.0")
                  )}
                </strong>
              </span>
            </div>
            <div className="px-4 rounded-pill">
              <span className="p-1 text-center d-flex align-items-center">
                <h6 className="mb-0">Net Worth:&nbsp;</h6>
                <strong>
                  {loading ? ("--") : (
                    `$${formatWithCommas(
                      Number(vaultNetWorth)?.toFixed(2) || "0.0"
                    )}`
                  )}
                </strong>
              </span>
            </div>
            <div className="px-4 rounded-pill">
              <span className="p-1 text-center d-flex align-items-center">
                <h6 className="mb-0">APY:&nbsp;</h6>
                <strong>
                  {loading ? ("--") : (
                    `${vaultAPY?.toFixed(2)}%`
                  )}
                </strong>
              </span>
            </div>
            <div>
              <Tooltip title={loading ? "Updating..." : "Update Data"}>
                <GrDocumentUpdate
                  className="me-3"
                  style={{ cursor: "pointer" }}
                  onClick={handleUpdate}
                />
              </Tooltip>
            </div>
            <div>
              <VaultDetails />
            </div>
          </div>
        </div>
        <Container fluid style={{ padding: "20px" }}>
          <Row>
            <Col md={3}>
              <div className="d-flex justify-content-center">
                <div className="d-flex">
                  <div className="text-center">
                    <Flex gap="small" wrap>
                      <Progress
                        type="circle"
                        percent={wallet.connected ? ProcessPercent : "0"}
                        strokeColor={conicColors}
                      />
                    </Flex>
                    <p>
                      In Supply{" "}
                      <Tooltip title="Supply % = Assets Supplied / Total Available Assets">
                        <span>
                          <MdOutlineInfo />
                        </span>
                      </Tooltip>
                    </p>
                  </div>
                  <div className="mt-4 ms-4">
                    <div className="d-flex">
                      <h5>Account Cap</h5>
                    </div>
                    <div className="d-flex align-items-center">
                      <Badge pill bg="success">
                        {wallet?.connected ? (
                          <a
                            href={Suivision + `${config?.naviAccountCap}`}
                            className="text-light"
                            style={{ textDecoration: "none" }}
                            target="_blank"
                            rel="noreferrer"
                          >
                            {naviAccountCap || "--"}
                          </a>
                        ) : (
                          "--"
                        )}
                      </Badge>
                      {wallet.connected && (
                        <IoMdCopy
                          className="ms-2"
                          style={{ cursor: "pointer" }}
                          onClick={handleCopy}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="ms-auto">
              <Row>
                <Col md={4} className="form-section">
                  <div>
                    <h5>
                      <strong>Navi Position</strong>
                    </h5>
                    <ul className="list-unstyled">
                      <li>
                        SUI Index Price:{" "}
                        <span className="data-field">
                          <strong>{parseFloat(suiPrice).toFixed(4)}</strong>
                        </span>
                      </li>
                      <li>
                        WETH Index Price:{" "}
                        <span className="data-field">
                          <strong>{parseFloat(wethPrice).toFixed(2)}</strong>
                        </span>
                      </li>
                      <li>
                        Health Factor:{" "}
                        <span className="data-field">
                          <strong>
                            {" "}
                            {wallet?.connected ? (
                              <strong
                                className={
                                  HealthFactor >= "0.00"
                                    ? "text-success"
                                    : "text-danger"
                                }
                              >
                                {parseFloat(HealthFactor) >= "10" ? (
                                  <FaInfinity className="fs-4" />
                                ) : (
                                  HealthFactor
                                )}
                              </strong>
                            ) : (
                              <strong>0.00</strong>
                            )}
                          </strong>
                        </span>
                      </li>
                    </ul>
                  </div>
                </Col>
                <Col md={4} className="form-section">
                  <div>
                    <h5>
                      <strong>Bluefin SUI-PERP</strong>
                      <FormAssetdetail
                        title={"Bluefin SUI-PERP"}
                        marginAsset={setMargin}
                        PositionPriceAsset={setPositionPrice}
                        PositionSizeAsset={setposition_Size}
                      />
                    </h5>
                    <ul className="list-unstyled">
                      <li>
                        Funding Rate:{" "}
                        <span
                          className={
                            SuiFundingRate >= "0.00"
                              ? "text-success data-field"
                              : "text-danger data-field"
                          }
                        >
                          <strong>{SuiFundingRate}%</strong>
                        </span>
                      </li>
                      <li style={{ marginBottom: "20px" }}>
                        Oracle Price:{" "}
                        <span className="data-field">
                          <strong>${SuiOraclePrice}</strong>
                        </span>
                      </li>
                      <li style={{ display: "none" }}>
                        MMR:{" "}
                        <span className="data-field">
                          <strong>{SuiMMR}</strong>
                        </span>
                      </li>
                      <li>
                        Position Size:{" "}
                        <span className="data-field">
                          <strong>{position_Size || "0.0"} SUI</strong>
                        </span>
                      </li>
                      <li>
                        Position Entry Price:{" "}
                        <span className="data-field">
                          <strong>${positionPrice || "0.0"}</strong>
                        </span>
                      </li>
                      <li style={{ marginBottom: "20px" }}>
                        Margin:{" "}
                        <span className="data-field">
                          <strong>${margin || "0.0"}</strong>
                        </span>
                      </li>
                      {parseFloat(position_Size) > 0 && (
                        <div>
                          <li>
                            Position PnL:{" "}
                            <span
                              className={
                                SuiPositionPnL >= "0.00"
                                  ? "text-success data-field"
                                  : "text-danger data-field"
                              }
                            >
                              <strong>${SuiPositionPnL}</strong>
                            </span>
                          </li>
                          <li>
                            Liquidation Price:{" "}
                            <span className="data-field">
                              <strong>${SuiLiquidationPrice}</strong> (
                              {distanceFromSuiLiq}%)
                            </span>
                          </li>
                          <li>
                            Leverage:{" "}
                            <span className="data-field">
                              <strong>{SuiLeverage}</strong>
                            </span>
                          </li>
                        </div>
                      )}
                    </ul>
                  </div>
                </Col>

                <Col md={4} className="form-section">
                  <div>
                    <h5>
                      <strong>Bluefin ETH-PERP</strong>
                      <FormAssetdetail
                        title={"Bluefin ETH-PERP"}
                        marginAsset={setMarginEth}
                        PositionPriceAsset={setPositionPriceEth}
                        PositionSizeAsset={setposition_SizeEth}
                      />
                    </h5>
                    <ul className="list-unstyled">
                      <li>
                        Funding Rate:{" "}
                        <span
                          className={
                            EthFundingRate >= "0.00"
                              ? "text-success data-field"
                              : "text-danger data-field"
                          }
                        >
                          <strong>{EthFundingRate}%</strong>
                        </span>
                      </li>
                      <li style={{ marginBottom: "20px" }}>
                        Oracle Price:{" "}
                        <span className="data-field">
                          <strong>${EthOraclePrice}</strong>
                        </span>
                      </li>
                      <li style={{ display: "none" }}>
                        MMR:{" "}
                        <span className="data-field">
                          <strong>{EthMMR}</strong>
                        </span>
                      </li>
                      <li>
                        Position Size:{" "}
                        <span className="data-field">
                          <strong>{position_SizeEth || "0.0"} ETH</strong>
                        </span>
                      </li>
                      <li>
                        Position Entry Price:{" "}
                        <span className="data-field">
                          <strong>${positionPriceEth || "0.0"}</strong>
                        </span>
                      </li>
                      <li style={{ marginBottom: "20px" }}>
                        Margin:{" "}
                        <span className="data-field">
                          <strong>${marginEth || "0.0"}</strong>
                        </span>
                      </li>
                      {parseFloat(position_SizeEth) > 0 && (
                        <div>
                          <li>
                            Position PnL:{" "}
                            <span
                              className={
                                EthPositionPnL >= "0.00"
                                  ? "text-success data-field"
                                  : "text-danger data-field"
                              }
                            >
                              <strong>${EthPositionPnL}</strong>
                            </span>
                          </li>
                          <li>
                            Liquidation Price:{" "}
                            <span className="data-field">
                              <strong>${EthLiquidationPrice}</strong> (
                              {distanceFromEthLiq}%)
                            </span>
                          </li>
                          <li>
                            Leverage:{" "}
                            <span className="data-field">
                              <strong>{EthLeverage}</strong>
                            </span>
                          </li>
                        </div>
                      )}
                    </ul>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Card>
    </div>
  );
}

export default AssetsDetails;
